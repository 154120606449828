Form,
div.form {
  border-radius: 25px;
  padding: 20px;
  background-color: #efefe9;
}
Button,
button {
  border-radius: 25px;
  padding: 0;
}
MuiPickersUtilsProvider {
  background-color: #a6bbc4 !important;
  background-color: red !important;
  color: red !important;
}
button[type="reset"] {
  margin-left: 40px !important;
}
